export default {
  HOME: "HOME",
  "COMPASS: enhancing motivation of older learners to learn through cultural materials":
    "COMPASS: enhancing motivation of older learners to learn through cultural materials",
  "Project number 2020-1-DE02-KA204-007391":
    "Project number 2020-1-DE02-KA204-007391",
  "COMPASS is an initiative born from the need identified in all partner countries to motivate older learners to take a more active part in lifelong learning. In our current globalized world foreign languages are an essential vehicle to ensure communication among societies. In parallel, it is of human nature to feel identified and represented by a culture, a society, a community; these define our values, beliefs and way of relating to the others and the world. Being able to express such identifications by story-telling and creating of narratives in foreign languages is indeed a source of motivation and inspiration.":
    "COMPASS is an initiative born from the need identified in all partner countries to motivate older learners to take a more active part in lifelong learning. In our current globalized world foreign languages are an essential vehicle to ensure communication among societies. In parallel, it is of human nature to feel identified and represented by a culture, a society, a community; these define our values, beliefs and way of relating to the others and the world. Being able to express such identifications by story-telling and creating of narratives in foreign languages is indeed a source of motivation and inspiration.",
  "As an educator, the COMPASS project will help you combine cultural heritage with foreign language learning in a very creative way and with a CLIL approach. You will learn how to embed digital practices in your educational activities, experiment with cultural narratives in our digital books and find out about cultural heritage sites thanks to our geocaching routes! ":
    "As an educator, the COMPASS project will help you combine cultural heritage with foreign language learning in a very creative way and with a CLIL approach. You will learn how to embed digital practices in your educational activities, experiment with cultural narratives in our digital books and find out about cultural heritage sites thanks to our geocaching routes! ",
  "COMPASS materials will help you extend your professional competences and be more able to motivate older people to engage in lifelong learning.":
    "COMPASS materials will help you extend your professional competences and be more able to motivate older people to engage in lifelong learning.",
  "Start browsing around all our web sections on this platform and find out all materials and COMPASS project has to offer you!":
    "Start browsing around all our web sections on this platform and find out all materials and COMPASS project has to offer you!",
  "The project": "The project",
  TIMELINE: "TIMELINE",
  "Online Kick-off meeting: January 2021":
    "Kick-off meeting: September 2021, Norden (Germany)",
  "Second Transnational meeting: Ogre (Latvia)  ":
    "Second Transnational meeting: November 2021 Riga (Latvia)",
  "TOOL BANK - November 2021\n\n": "TOOL BANK - November 2021\n\n",
  "Third Transnational Meeting: Reus (Spain) ":
    "Third Transnational Meeting: Reus (Spain) ",
  "Learning and Training Activity: Cori (Italy)":
    "Learning and Training Activity: July 2022 Cori (Italy)",
  "BLENDED COURSE - August 2022": "BLENDED COURSE - August 2022",
  "Fourth Transnational Meeting: Rijeka (Croatia)":
    "Fourth Transnational Meeting: Rijeka (Croatia)",
  "DIGITAL BOOK - February 2023": "DIGITAL BOOK - February 2023",
  "Fifth Transnational Meeting: Aveiro, (Portugal)":
    "Fifth Transnational Meeting: Aveiro, (Portugal)",
  "GEOCATCHING CULTURAL ROUTE - August 2023":
    "GEOCATCHING CULTURAL ROUTE - August 2023",
  "Multiplier events: Germany, Portugal, Spain, Italy and Croatia":
    "Multiplier events: Germany, Portugal, Spain, Italy and Croatia",
  "TOOL BANK": "TOOL BANK",
  "The tool bank will include the practical examples of CLIL activities ready to be deployed by adult educators. These will be activities which will combine cultural heritage background and items to teach foreign languages. As such, it will become a great means to extend professional competences of adult educators in order to provide them with the necessary knowledge to improve language and intercultural skills of their adult learners and trigger their motivation to learn.":
    "The tool bank will include the practical examples of CLIL activities ready to be deployed by adult educators. These will be activities which will combine cultural heritage background and items to teach foreign languages. As such, it will become a great means to extend professional competences of adult educators in order to provide them with the necessary knowledge to improve language and intercultural skills of their adult learners and trigger their motivation to learn.",
  "Expected in November 2021": "Expected in November 2021",
  "BLENDED COURSE": "BLENDED COURSE",
  "If you are an educator working with older adults learning foreign languages, have a look at our new and innovative course “Cultural Narratives as a motivational trigger for learning in older learners”and learn everything you need to exploit cultural narrative and story-telling as a motivational trigger to engage older learners in lifelong learning.":
    "If you are an educator working with older adults learning foreign languages, have a look at our new and innovative course “Cultural Narratives as a motivational trigger for learning in older learners”and learn everything you need to exploit cultural narrative and story-telling as a motivational trigger to engage older learners in lifelong learning.",
  "Take advantage of everything EU cultural heritage has to offer to keep your language students motivated with a CLIL approach! Learn how to activate your learners’ creativity and self-expression by creating safe learning environments where they will be able to share their life-stories and cultural narratives.":
    "Take advantage of everything EU cultural heritage has to offer to keep your language students motivated with a CLIL approach! Learn how to activate your learners’ creativity and self-expression by creating safe learning environments where they will be able to share their life-stories and cultural narratives.",
  "START THE COURSE!": "START THE COURSE!",
  "Expected in August 2022": "Expected in August 2022",
  "DIGITAL BOOK": "DIGITAL BOOK",
  "Constructing Identity through Cultural memory":
    "Constructing Identity through Cultural memory",
  "Only if you are familiar with the topic and you have gone through the course materials, you are ready to start experimenting with cultural narratives!":
    "Only if you are familiar with the topic and you have gone through the course materials, you are ready to start experimenting with cultural narratives!",
  "Adults involved in COMPASS training activities have developed a Digital Book through a collaborative process in their local communities collecting and sharing knowledge and information about tangible and intangible culture elements in their communities and creating cultural narratives that will make all of us feel identified with the EU society.":
    "Adults involved in COMPASS training activities have developed a Digital Book through a collaborative process in their local communities collecting and sharing knowledge and information about tangible and intangible culture elements in their communities and creating cultural narratives that will make all of us feel identified with the EU society.",
  "These books will not only reflect the way older people relate and identify themselves with the cultural heritage of their communities but also serve as a practical tool for language learning.":
    "These books will not only reflect the way older people relate and identify themselves with the cultural heritage of their communities but also serve as a practical tool for language learning.",
  "Expected in February 2023": "Expected in February 2023",
  "GEOCATCHING CULTURAL ROUTE": "GEOCATCHING CULTURAL ROUTE",
  "Have a look at our virtual geocaching cultural routes to discover European Cultural Heritage through the usage of fun digital technologies. Thanks to dynamical quests, quizzes, QR codes, digital maps and a multi-player chat-bot-based channel, you will be able to visit Europe from your home.":
    "Have a look at our virtual geocaching cultural routes to discover European Cultural Heritage through the usage of fun digital technologies. Thanks to dynamical quests, quizzes, QR codes, digital maps and a multi-player chat-bot-based channel, you will be able to visit Europe from your home.",
  "Cultural Heritage sites have never been more accessible to all. Ready for some new experiences?":
    "Cultural Heritage sites have never been more accessible to all. Ready for some new experiences?",
  "Expected in August 2023": "Expected in August 2023",
  NEWS: "NEWS",
  "Read about COMPASS latest activities and events. Stay tuned and follow us on all our social media channels!":
    "Read about COMPASS latest activities and events. Stay tuned and follow us on all our social media channels!",
  "OUR PARTNERS": "OUR PARTNERS",
  "Languages": "Languages",
  "Would you like to meet the whole team?":
    "Would you like to meet the whole team?",
  "These are the COMPASS partners:": "These are the COMPASS partners:",
  "CONTACT US": "CONTACT US",
  "Hello! Let's talk!": "Hello! Let's talk!",
  "We would love to hear from you. Drop us , whether it is a comment, a question, a work proposition or just to say hello.":
    "We would love to hear from you. Drop us , whether it is a comment, a question, a work proposition or just to say hello.",
  "Please, use the form below.": "Please, use the form below.",
  "Name & Surname": "Name & Surname",
  "E-mail Address": "E-mail Address",
  Subject: "Subject",
  Message: "Message",
  "Send Message": "Send Message",
  Duration: "Duration",
  "Activity description": "Activity description",
  "Training method": "Training method",
  "Materials/Equipment": "Materials/Equipment",
  Module: "Module",
  "Back to modules": "Back to modules",
  "Click Here!": "Click Here!",
  "GEOCACHING CULTURAL ROUTE": "GEOCACHING CULTURE ROUTE",
  "GCRp1": "The Compass Geocaching Culture Route is a game based on the classic \"treasure hunt\" concept, where teams compete against each other to reach locations as quickly as possible and solve dynamic quiz questions. The goal is to support participants in language learning through a creative method while establishing a cultural connection simultaneously.",
  "GCRp2": "HOW TO ORGANIZE YOUR OWN GAME WITH OLDER PEOPLE?",
  "GCRp2a": " Tips for teachers: ",
  "GCRp3": "Ensure you find a location that is easily accessible",
  "GCRp4": "Create your own route by printing and placing the QR codes generated by the bot. To experiment with your own routes, you can use the code to create the routes::",
  "GCRp4a": "https://github.com/",
  "GCRp5": "Support each group of adults with a group leader who guides the group in using the technology. ",
  "GCRp6": "If you need assistance in creating the Compass Geocaching Culture Route, feel free to contact our partners in Italy: Address: APS Polygonal, Via Elio Filippo Accrocca 1, 04010 Cori.",
  "GCRp7": "If you would like to experience the Compass Geocaching Cultural Route, which has already been created as part of the project, in person, you can get in touch with the following contacts: ",
  "GCRp7a": "Germany: KVHS Norden, Customer Team, Uffenstraße 1, 26506 Norden, Tel. +49 49311870123 or info@kvhs-norden.de. ",
  "GCRp7b": "Croatia: Volkshochschule Dante, Wenzelova 2, 51000, RIJEKA. ",
  "GCRp7c": "Latvia: Foundation for Education and Innovation \"Zini,\" Brivibas Street, 117-42, LV-5001, Riga. ",
  "GCRp7d": "Portugal: UNAVE, Association for Vocational Training and Research of the University Aveiro, Campus Universitariosde Santiago, 3810-193 Aveiro. ",
  "GCRp7e": "Universidade de Aveiro, Campus Universitário de Santiago, 3810-193 Aveiro. ",
  "GCRp7f": "Italy: APS Polygonal, Via Elio Filippo Accrocca 1, 04010 Cori. ",
  "GCRp7g": "Spain: DOMSPAIN CONSULTING SL, RAVAL SANT PERE, 1 ENTRESOL, 43201 Reus.",
  "GCRp8": "Example from the Project: All maps developed by us are fully translated, allowing participants to easily compare and learn words. ",
  "GCRp9": "DOWNLOAD THE EUROPE MAPS ",
  "GCRp10": "⬇️ Germany ",
  "GCRp11": "⬇️ Croatia ",
  "GCRp12": "⬇️ Latvia ",
  "GCRp13": "⬇️ Portugal ",
  "GCRp14": "⬇️ Italy ",
  "GCRp15": "⬇️ Spain ",
  "GCRp16": "How to Play the Cultural Geocaching Routes:",
  "GCRp17": "We have developed a digital tool to promote active aging and facilitate language acquisition, making language learning more enjoyable and connected to cultural heritage. We've created an interesting guide describing the locations covered in COMPASS in simple language. We've developed quizzes supporting language learning that can be easily understood by learners of all ages, emphasizing simplicity and clarity in language. Using Telegram chatbots, we've designed an unconventional treasure hunt chat where participants are challenged with simple knowledge tasks while having the opportunity to explore cultural heritage patterns selected by COMPASS educators at their own pace.",
  "GCRp19": "Game:",
  "GCRp20": "Groups/Teams: ",
  "GCRp21": "All players in a game session form teams (ideally consisting of 2-10 people) competing against each other. ",
  "GCRp22": "Each team chooses a team leader who represents the entire team during the game. The team leader interacts with the game bot through their Telegram account. ",
  "GCRp23": "Other players in the same team assist the team leader in reaching locations, searching for QR codes, and solving dynamic quiz questions.",
  "GCRp24": "Registration/Sign-up:",
  "GCRp25": "To participate in a game, the team leader must initially scan the registration QR code on behalf of their team. The QR code is distributed by the game organizers before the game. ",
  "GCRp26": "Team registration conversation with the bot.",
  "GCRp27": "With the ensuing conversation with the bot, the following questions will be asked:",
  "GCRp28": "1. A simple introduction quiz to verify that players understand the game’s rules,",
  "GCRp29": "2. The team’s name,",
  "GCRp30": "3. The number of team members (team leader included),",
  "GCRp31": "4. An avatar picture that represents the team (or a picture of the team itself).",
  "GCRp32": "GAME STRUCTURE",
  "GCRp33": "Teams reaching different locations.",
  "GCRp34": "All teams gather at the game’s starting location, at a given time, provided by the game organizers ahead of the game.",
  "GCRp35": "Organizers will show players the start QR Code (that is, the code identifying the starting location).",
  "GCRp36": "As soon as team leaders scan the code, the game will start.",
  "GCRp37": "The game si structured as follows:",
  "GCRp38": "1. The team reaches a location (either the starting location or any intermediate one),",
  "GCRp39": "2. The team snaps a selfie, which serves as proof of reaching the location,",
  "GCRp40": "3. A quiz is assigned and solved by the players (the correct answer must be sent to the bot as a simple text message, if the given solution is wrong the team will have to wait for 30 seconds before trying again),",
  "GCRp41": "4. The next location is assigned, go to step (1); if all locations have been reached, the last location is assigned,",
  "GCRp42": "5. Last team selfie,",
  "GCRp43": "6. The final quiz is assigned and solved by the team,",
  "GCRp44": "7. The game is over.",
  "GCRp45": "Quiz assigned and solved by the players.",
  "GCRp46": "Final quiz assigned and solved by the team.",
  "GCRp47": "REQUIREMENTS BEFORE STARTING",
  "GCRp48": "Compass Geocaching cultural route makes use of the Telegram instant messaging platform in order to offer an accessible and easy-to-use game experience, both for players and game administrators. The game is based on the classical “treasure hunt” scheme, with player teams challenging each other looking for specific places and solving quizzes.",
  "GCRp49": "Each game location is identified by a square barcode (a so-called “QR Code”).",
  "GCRp50": "WHAT YOU’LL NEED TO PLAY",
  "GCRp51": "The following requirements must be satisfied by the game administrator and by at least one person for each team (the so-called “team leader”).",
  "GCRp52": "📱 Smartphone (with a fully charged battery 🔋),",
  "GCRp53": "👨‍💼 Telegram account,",
  "GCRp54": "🌐 Data connection,",
  "GCRp55": "📷 QR Code scanner app.",
  "GCRp56": "Read below for additional details.",
  "GCRp57": "SMARTPHONE",
  "GCRp58": "Any recent smartphone with GPS and a photocamera can be used to play the game.",
  "GCRp59": "Compass Geocaching cultural route has been successfully tested on the following operating systems: iOS or Android",
  "GCRp60": "TELEGRAM ACCOUNT",
  "GCRp61": "Telegram is an instant messaging system (just like WhatsApp or Facebook Messenger), freely available on the App Store and Google Play Store.",
  "GCRp62": "In order to use Telegram you must first register and activate a user account, which can also be done for free through the application, by providing a working mobile phone number.",
  "GCRp63": "Only administrators and team leaders need a Telegram account. However, Compass Geocaching cultural route allows anybody to follow an active game through Telegram “public channels”, to which any Telegram user can subscribe to.",
  "GCRp64": "DATA CONNECTION",
  "GCRp65": "The game is built on a conversation with a Telegram “bot”, which collects information and provides instructions to players. This communication requires an active data connection.",
  "GCRp66": "In some indoor places there might be a spotty or poor cell phone coverage: in this case it might be useful to check for the presence of Wi-Fi connections. Remember that communication with a Telegram bot is opportunistic: if you scan a QR Code or provide a quiz solution while no data connection is available, Telegram will re-establish the communication as soon as possible.",
  "GCRp67": "BATTERY CHARGE",
  "GCRp68": "Scanning QR Codes, using the GPS, and communicating with the bot requires power: make sure that the team leader’s smartphone is fully charged before starting the game. It may be useful to bring a portable USB charger with you.",
  "GCRp69": "QR CODE SCANNER",
  "GCRp70": "A QR Code scanner is a mobile phone application that scans the barcodes used by the game to identify locations.",
  "GCRp71": "First, make sure whether your operating system supports QR Code scanning out of the box. Open the Camera app and point it towards the following QR Code. On iOS or recent Android versions, a clickable link overlay should be displayed.",
  "GCRp72": "Once you click on the link, the Telegram app will be launched. Click on “start” in order to send the link to the bot, which will provide confirmation that the QR Code scanner works.",
  "GCRp73": "If your camera app does not work, you will need to install a QR Code scanner app.",
  "disclaimer1": "The European Commission support for the production of this publication does not constitute an endorsement of the contents which reflects the views only of the authors, and the Commission cannot be held responsible for any use which may be made of the information contained therein",
  "disclaimer2": "Despite careful research, it is possible that the images used are protected by copyright. If you are the author of one of the images used and our information on usage is not correct, please contact us.  "

}
